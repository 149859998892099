body {
  color: #253858 !important;
  font-size: 12px;
}

[hidden] {
  display: none !important;
}

.ctable {
  border-collapse: collapse;
  width: 100%;
}

.ctable .csticky {
  position: relative;
  border-collapse: collapse;
}

.ctable .csticky th {
  background: #f5f5f5;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 2px 2px 3px #ccc;
  z-index: 99999;
}

.ctable,
.ctable td,
.ctable th {
  border: 1px solid #ccc;
  vertical-align: middle;
}

.ctable.no-border-top,
.ctable.no-border-top td,
.ctable.no-border-top th {
  border-top: 0px solid #ccc !important;
}

.ctable.no-border-left,
.ctable.no-border-left td,
.ctable.no-border-left th {
  border-left: 0px solid #ccc !important;
}

.ctable table,
.ctable table td,
.ctable table th {
  border: 0px solid #ccc;
}

.ctable th {
  text-align: left;
}

.ctable td,
.ctable th {
  padding: 5px 10px;
}

.ctable table td,
.ctable table th {
  padding: 1px 2px;
}

.ctable th.fit,
.ctable td.fit {
  width: 10px !important;
  max-width: auto !important;
  white-space: nowrap;
  text-align: center;
}

.ctable .mat-icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.ctable .mat-icon-button {
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.ctable .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.ctable .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0 0;
}

.ctable .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: -3px;
}

.ctable .text-center {
  text-align: center !important;
}

.ctable .text-right {
  text-align: right !important;
}

.ctable .text-left {
  text-align: left !important;
}

.ctable .text-center .color-box {
  margin: 0 auto !important;
}

.ctable.noborder,
.ctable.noborder td,
.ctable.noborder th {
  border: 0px solid #ccc !important;
}

.ctable.border,
.ctable.border td,
.ctable.border th {
  border: 1px solid #ccc !important;
}

.ctable .empty-cell {
  background: #f9f9f9;
}

.ctable .dx-field {
  min-height: 35px !important;
  width: 100%;
}

.ctable .img-thumb {
  width: 100px;
  height: auto;
}

.header-bg {
  background: #eeeeee !important;
}

.white-bg {
  background: #ffffff !important;
}

/* ctable end */

.ng-select .ng-select-container {
  border: 0px solid #fff !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0px !important;
}

/* Mat Dialog customization */
.mat-dialog-actions {
  padding: 8px 0 !important;
}

.mat-dialog-content {
  padding: 12px 16px !important;
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}

.mat-dialog-title {
  margin: 0 0 5px !important;
}

.mat-dialog-container {
  padding-top: 15px !important;
}

/* Mat Dialog customization end */

/* component */
.color-box {
  width: 12px;
  height: 12px;
  border-radius: 10%;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ccc;
  margin-top: -4px;
  margin-right: 3px;
  text-align: center;
  font-size: 8px;
}

.colorbox {
  width: 12px;
  height: 12px;
  border-radius: 10%;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ccc;
  margin-top: -4px;
  margin-right: 3px;
  text-align: center;
  font-size: 8px;
  opacity: 0.6;
}

/* component end*/

.page-layout.carded.fullwidth>.center>.header {
  height: 75px !important;
  max-height: 75px !important;
  min-height: 75px !important;
}

/* title */
.mat-title,
.palate-header .mate-title {
  color: #656565;
  font-size: 16px;
}

.mat-title .mat-icon-button,
.palate-header .mat-icon-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #656565;
}

.mat-title .mat-icon,
.palate-header .mat-icon {
  font-size: 18px !important;
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  min-height: 18px !important;
  line-height: 16px !important;
}

.palate-header {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* title end*/

/* list item */
.no-messages-text {
  font-size: 24px;
  font-weight: 300;
}

.erp-list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.current-item {
  background: #e2ebfc;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.list-item {
  font-size: 11px;
  line-height: 11px;
  flex-shrink: 0;
  position: relative;
  padding: 7px 0px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;

  &.unread {
    background: #ffffff;

    .info {
      .name {
        font-weight: 700;
      }
    }
  }

  &.selected {
    background: #fff8e1;

    .info {
      .row-2 {
        .labels {
          background: #fff8e1;
        }
      }
    }
  }

  .info {
    overflow: hidden;
    width: 0;
    position: relative;

    .progress {
      padding: 10px 10px 0 !important;

      .pro-part {
        margin-bottom: 10px;
      }

      .pro-part p {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 0;
      }

      .mat-progress-bar {
        height: 10px !important;
        opacity: 0.8;
      }

      .mat-progress-bar-fill::after {
        background-color: #4caf50 !important;
      }

      .mat-progress-bar-buffer {
        background-color: #ccc !important;
      }
    }
  }
}

.side-panel-progress {
  overflow: hidden;
  width: 100%;
  position: relative;

  .progress {
    padding: 10px 0 0 !important;

    .pro-part {
      margin-bottom: 10px;
    }

    .pro-part p {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 0;
    }

    .mat-progress-bar {
      height: 12px !important;
      opacity: 0.8;
    }

    .mat-progress-bar-fill::after {
      background-color: #4caf50 !important;
    }

    .mat-progress-bar-buffer {
      background-color: #ccc !important;
    }
  }
}

.color-circle {
  width: 15px;
  text-align: center;
  display: inline-block;
  height: 15px;
  margin-right: 5px;

  .circle {
    margin: 0 auto;
    width: 12px;
    height: 12px;
    border-radius: 20%;
    display: block;
    border: 1px solid #ccc;
    opacity: 0.5;
  }

  .status {
    margin-top: 3px;
    margin: 0 auto;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    display: block;
    background: red;
  }
}

.with-icon {
  display: flex;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.font-medium {
  font-size: 14px;
  text-transform: capitalize;
}

.font-big {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 13px;
}

.font-regular {
  font-size: 12px;
  text-transform: capitalize;
}

.font-small {
  font-size: 11px;
  text-transform: capitalize;
}

.font-tiny {
  font-size: 10px;
  text-transform: capitalize;
}

.font-huge {
  font-size: 20px;
  text-transform: capitalize;
}

.text-blue {
  color: #039be5 !important;
}

.text-gray {
  color: #acacac !important;
}

.text-blue-deep {
  color: #00496d !important;
}

.text-green {
  color: #4caf50 !important;
}

.text-red {
  color: #f44336 !important;
}

.text-transparent {
  color: transparent;
}

.text-yellow {
  color: yellow;
}

.thumb-image {
  width: auto !important;
  max-width: 70px !important;
  height: auto !important;
  max-height: 70px;
}

.list-item .thumb-image {
  width: auto !important;
  height: auto !important;
  max-height: 50px !important;
}

/* list item end */

/* side panel */
.list-side-panel {
  padding: 25px;
  font-size: 12px;
  line-height: 14px;
  width: 100%;

  p {
    margin-bottom: 3px;
  }

  .header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 10px;
  }

  .main-container {
    margin-top: 20px;
  }
}

/* side panel end*/
/* c card item*/
.ccard-panel {
  border: 1px solid #ccc;
}

.ccard-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 10px;
  font-size: 12px;
}

.ccard-item:last-child {
  border-bottom: 0px solid #ccc;
}

.ccard-item p {
  padding: 5 0 !important;
  margin: 0 !important;
}

.ccard-item .regular {
  font-size: 12px;
  text-transform: capitalize;
}

.ccard-item .small {
  font-size: 11px;
  text-transform: capitalize;
}

.ccard-item .big {
  font-size: 14px;
  text-transform: capitalize;
}

.ccard-item .bold {
  font-weight: bold;
}

.ccard-item .transparent {
  color: transparent;
}

/* c card item end*/

/* cportlet*/
.cportlet {
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #ccc;
  overflow: hidden;

  p {
    margin-bottom: 3px;
  }

  .mat-title {
    margin-left: 12px;
  }

  .sm.mat-icon-button {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }

  .sm mat-icon {
    font-size: 15px;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    line-height: 15px;
  }
}

.cportlet.visible {
  overflow: visible;
}

/* cportlet end*/

.chip {
  background: #bca5ff;
  font-size: 9px;
  font-weight: bold;
  border-radius: 5px;
  padding: 3px;
  color: #fff;
  text-transform: uppercase;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.scrollable {
  width: auto;
  flex: 1 1 auto;
  display: flex !important;
  flex-direction: column;
  min-width: 100%;
  position: relative;
}

/* ng2-dragula start */
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.drag-cursor {
  cursor: move;
}

/* ng2-dragula end */

.trim-list.tiles .tile {
  text-align: center;
  float: left;
  width: 25%;
  height: auto;
  box-sizing: border-box;
  color: #171e42;
  padding: 10px;
  height: 150px;
  cursor: pointer;
}

.trim-list.tiles .tile .list-thumb {
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid #ccc;
}

.trim-list.tiles .tile .list-thumb img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: auto;
  height: 100%;
  max-height: 140px;
  padding: 3px;
}

.trim-list.tiles .tile:hover .list-thumb {
  border: 1px solid #273561;
}

/*

*/

#product .content .mat-tab-label {
  height: 30px !important;
}

.table-editable-border td {
  border-bottom: 1px solid #f44336 !important;
}

/* start step process component*/

.process-item {
  position: relative;
}

.process-pointer {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ccc;
  position: absolute;
  left: 50%;
  top: 0;
}

.process-line {
  height: 1px;
  background: #ccc;
  margin-top: 7px;
}

.process-title {
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
  padding-left: 10px;
}

.process-current-state .process-pointer {
  background: #4caf50 !important;
}

.process-current-state .process-line {
  background: #4caf50 !important;
}

/* end step process component*/

.background-gray-light {
  background-color: #e2e2e2;
}

.width-auto {
  width: auto !important;
}

/* image start */
.photo-thumbs {
  position: relative;
}

.photo-thumbs .thumb {
  height: 80px;
  width: 80px;
  margin-right: 5px;
  margin-bottom: 5px;
  float: left;
  border: 1px solid #ccc;
  position: relative;
}

.photo-thumbs .thumb img {
  width: auto;
  height: 100%;
  cursor: pointer;
}

.photo-thumbs .thumb .thumb-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
}

/* image end */

/* hover div display start */
.hover-wrap {
  position: relative;
}

.hover-wrap .hover-div {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  border: 1px solid #666;
  z-index: 1;
  background: #fff;
  overflow: visible;
  padding: 4px;
  font-size: 11px;
  box-shadow: 10px 10px 15px #ccc;
}

.hover-wrap:hover .hover-div {
  display: block;
}

/* hover div display end */

.width-auto {
  width: auto;
}

.mw-110 {
  min-width: 110px;
}

.mw-100 {
  min-width: 100px;
}

.cursor-pointer {
  cursor: pointer;
}

.unit-highlight {
  border-bottom: 1px solid red;
}

.table-dirty {
  border: 1px solid red !important;
}

/* theme start */
body.theme-default .warn {
  top: 300px !important;
}

// filter drawer btn
.page-layout.carded.fullwidth>.center {
  padding: 0 !important;
}

.page-layout.carded.left-sidebar>.center,
.page-layout.carded.right-sidebar>.center {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

body.theme-default .page-layout.carded.fullwidth>.center>.content-card {
  border-radius: 0 !important;
}

.nav.material2 .nav-item .nav-link,
.material2 .nav .nav-item .nav-link {
  padding: 0 12px 0 12px !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
}

.page-layout.carded.fullwidth>.center>.header {
  height: 60px !important;
  max-height: 60px !important;
  min-height: 60px !important;
  padding: 0px 12px;
  background-color: #f5f5f5 !important;
  color: #363535 !important;
}

.nav.material2 .nav-group>.group-title,
.material2 .nav .nav-group>.group-title .hint-text {
  color: #5a1212 !important;
  font-size: 14px;
  font-weight: bold;
}

.left-navbar .navbar-top.fuse-navy-900 {
  background-color: #f5f5f5 !important;
}

.left-navbar .navbar-top .mat-icon.material-icons {
  color: #4e4e4e !important;
}

.left-navbar .navbar-top {
  min-height: 63px !important;
  max-height: 63px !important;
  height: 63px !important;
}

.left-navbar .navbar-top .logo-text {
  color: #4e4e4e !important;
  font-weight: bold !important;
}

.left-navbar .navbar-scroll-container.fuse-navy-700 {
  background-color: #ffffff !important;
  color: #363535 !important;
  font-weight: bold;
}

.nav .nav-item .nav-link.accent {
  background-color: #f5f5f5 !important;
  color: #cf0000 !important;
}

.nav>.nav-group>.group-items>.nav-collapsable.open {
  background-color: #f5f5f5 !important;
}

.navbar-fuse-sidebar {
  box-shadow: none !important;
  border-right: 1px solid #d6d6d6;
}

.vertical-style-1 .navbar-scroll-container {
  background: none !important;
  border-top: 1px solid #ccc !important;
}

body.theme-default .mat-elevation-z1 {
  box-shadow: none !important;
  border-bottom: 1px solid #ccc;
}

body.theme-default .mat-tab-label,
body.theme-default .mat-tab-link:hover {
  text-decoration: none !important;
}

a.mat-tab-link.ng-tns-c36-8.ng-star-inserted.mat-tab-label-active.mat-tab-label-active {
  background: #eeeeee !important;
  color: #000000 !important;
}

/* ink bar style */
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: #919090 !important;
}

// ** navigation
.nav .nav-group .group-items>.nav-collapsable>.children {
  margin-left: 20px;
}

.nav .nav-group .group-items>.nav-collapsable>.children>.nav-collapsable>.children {
  margin-left: 20px;
}

/* theme end */

.snackbar-custom {
  border: 1px solid #ea9796;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.snackbar-success {
  border: 1px solid green;
  background: #fff !important;
  color: green !important;
}

.snackbar-warning {
  border: 1px solid #fdd835;
  background: #fff !important;
  color: #fdd835 !important;
}

.snackbar-error {
  border: 1px solid #cf0000;
  background: #fff !important;
  color: #cf0000 !important;
}

.snackbar-backend-error {
  border: 1px solid #cf0000;
  background: #fff !important;
  color: #cf0000 !important;
}

/* Style the tab */
.tab {
  overflow: hidden;
  // border: 1px solid #ccc;
  // background-color: #f1f1f1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 13px 20px 11px;
  transition: 0.3s;
  border-bottom: 2px solid #fff;
  color: #7d7d7d;
  font-size: 15px;
}

.tab button:hover {
  background-color: rgba(0, 0, 0, 0.12);
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.tab button.active {
  background-color: rgba(0, 0, 0, 0.12);
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}

/* Style the tab end */

/* carton */

.carton-list .tile {
  border: 1px solid #ccc;
  margin-bottom: 5px;
  border-radius: 3px;
  float: left;
  padding: 0.4%;
  margin-right: 0.4%;
  transition: 0.25s;
  background: #fff;
  cursor: pointer;
}

.carton-list .tile.selected {
  border: 1px solid #cf0000;
  background: rgb(221, 221, 221);
  transition: 0.25s;
}

.carton-list .border-bottom {
  border-bottom: 1px solid #ccc;
}

.carton-list .cheps {
  border: 1px solid #ccc !important;
  padding: 2px !important;
  margin-right: 0px !important;
  border-radius: 2px !important;
}

.cheps {
  border: 1px solid #ccc !important;
  padding: 2px !important;
  margin-right: 4px !important;
  border-radius: 3px !important;
}

.mat-multiple-select {
  background: #fafafb;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc;
}

/* header filter bar start */

.custom-filter-header {
  background: #fff;
  border-bottom: 1px solid #ccc;
  padding: 12px;
}

.custom-filter-header .dx-field {
  min-height: 34px !important;
  background: #ffffff !important;
  padding: 0 4px 0 10px !important;
  border: 1px solid #ccc !important;
}

.custom-filter-header .dx-texteditor-input {
  font-weight: 200 !important;
  padding-right: 20px !important;
}

.custom-filter-header .dx-texteditor.dx-editor-standard {
  width: auto !important;
}

.custom-filter-header .dx-texteditor.dx-editor-standard:after {
  border-bottom: 0px solid #fff !important;
  font-weight: 200 !important;
}

.custom-filter-header .dx-texteditor-input {
  font-size: 13px;
  margin-top: 2px;
  padding-right: 20px !important;
}

.custom-filter-header .mat-multiple-select {
  background: #fff;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc !important;
}

.custom-filter-header .mat-slider {
  width: 100%;
}

.custom-filter-header .mat-slider {
  width: 100%;
  height: 35px !important;
}

.custom-filter-header .filter-input-field {
  height: 35px;
  font-weight: 200;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0px 8px;
}

.custom-filter-header .filter-btn-with-counter {
  position: relative;
}

.custom-filter-row-second {
  margin-top: 12px;
}

.custom-filter-header .filter-counter {
  font-size: 10px;
  height: 15px;
  width: 15px;
  background: #ff0000;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  right: 0;
  top: -20px;
  text-align: center;
  padding-top: 1px;
  z-index: 9;
}

.custom-filter-header .filter-btn {
  width: 33px;
  height: 33px;
  border-radius: 0;
  margin-right: 5px;
  box-shadow: 3px 3px 4px #ccc !important;
}

.custom-filter-header .filter-btn .material-icons {
  line-height: 16px;
  font-size: 18px;
}

.custom-filter-header .filter-submit {
  background: #318cef !important;
  color: #ffffff !important;
}

.custom-filter-header .filter-clear {
  background: #ffbf00 !important;
  color: #ffffff !important;
}

.custom-filter-header .filter-more-less {
  background: #363c56 !important;
  color: #ffffff !important;
}

.custom-filter-header .filter-pdf {
  background: #bfbfaa70 !important;
  color: #cf0000 !important;
}

.custom-filter-header .filter-pdf img {
  width: 20px;
  height: 20px;
  margin-top: -9px;
}

/* header filter bar end */

.attachment-chip {
  border: 1px solid #ccc;
  padding: 3px 5px 0px;
  width: 55px;
  display: inline-block;
  margin-left: 10px;
  border-radius: 4px;
}

.attachment-chip a {
  color: #4a4a4a !important;
}

/* start notification-card */
.notification-card {
  padding: 10px;
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
}

.notification-card:hover {
  background: #f7f7f7;
}

.notification-card.new {
  background: #edf2fa;
}

.notification-card.new:hover {
  background: #e8eef8;
}

.notification-card .cursor-pointer {
  cursor: pointer;
}

.notification-card .date {
  font-size: 10px;
  font-weight: bold;
}

/* end notification-card */

/* bar code */
.barcode-input-field {
  height: 30px;
  font-weight: 200;
  width: 200px;
  font-size: 14px;
  color: #1a1a1a;
  display: block;
  float: left;
  padding: 0 11px;
  border-top: 1px solid #b7b7b7;
  border-left: 1px solid #b7b7b7;
  border-bottom: 1px solid #b7b7b7;
}

.barcode-btn {
  width: 30px;
  height: 30px;
  border: 0px solid #fff;
  cursor: pointer;
  padding-top: 0;
  background: #318cef;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  border-top: 1px solid #b7b7b7;
  border-right: 1px solid #b7b7b7;
  border-bottom: 1px solid #b7b7b7;
}

.migrate-btn {
  height: 29px;
  color: white;
  background: #328cef;
  font-weight: 100;
  font-size: 15px;
  border: 0px;
  box-shadow: 2px 2px 2px #ccc;
}

.barcode-btn .mat-icon {
  display: block;
  margin-top: 3px;
  margin-left: -4px;
}

/* bar code */

.color-picker-square {
  padding: 0px !important;
  width: 34px !important;
  border: 1px solid #cccccc;
  height: 34px;
  border-radius: 50%;
}

/* Calendar */
.dhx_cal_tab.dhx_cal_tab_last.month_tab {
  left: 10px !important;
  border-radius: 2px !important;
}

.cal-arrow {
  margin-bottom: -45px !important;
  margin-top: 30px;
}

.cal-arrow .mat-flat-button {
  border-radius: 2px !important;
  color: #454544;
  border: 1px solid #cecece;
  text-shadow: 0 1px 0 #fff;
  text-decoration: none;
  cursor: default;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0px 10px;
  font-size: 12px;
  margin-right: 10px;
  line-height: 28px;
}

.cal-arrow * {
  z-index: 99999 !important;
}

.mat-button,
.mat-flat-button,
.mat-stroked-button {
  border-radius: 0 !important;
  text-transform: uppercase;
}

.toolbar-title-header {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 17px;
  /* font-variant: small-caps; */
  color: #4e4e4e;
}

.dhx_cal_container .dhx_cal_event_clear.dhx_cal_event_line_start.dhx_cal_event_line_end b {
  display: none !important;
}

/* single-thumb-uploader */

.single-thumb-uploader {
  border: 1px solid #ccc;
}

.single-thumb-uploader .single-thumb {
  width: 100%;
  height: 100%;
  position: relative;
}

.single-thumb-uploader .single-thumb img {
  width: auto;
  height: auto;
  padding: 5px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.single-thumb-uploader .single-thumb .thumb-uploader-delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9;
  color: #cf0000;
  width: 30px;
  height: 30px;
  border: none;
  text-align: center;
  font-size: 14px;
  display: none;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 10px #ccc;
}

.single-thumb-uploader .single-thumb:hover .thumb-uploader-delete-btn {
  display: block;
}

.single-thumb-uploader .thumb-uploader-box {
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
  cursor: pointer;
}

.single-thumb-uploader .thumb-uploader-box .fas {
  display: table-cell;
  vertical-align: middle;
  font-size: 30px;
  color: #ccc;
}

.page-list-header {
  color: #5c616f;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  background: #f5f5f5;
}

/* Dashboard */
.dashboard-panel-body {
  padding: 8px;
}

.dashboard-page .fuse-card {
  max-width: 100% !important;
  min-width: auto !important;
}

/* Dashboard end */

.note-toolbar i,
.note-toolbar mat-icon {
  font-size: 12px !important;
}

.dx-show-clear-button .dx-icon-clear {
  background-color: transparent !important;
}

.ctable input {
  border: 1px solid #ccc;
  padding: 5px;
  background: #fafafb;
}

.ctable .mat-form-field {
  width: 100% !important;
}

.ctable input.mat-input-element {
  min-height: 36px;
  font-size: 13px;
  color: #4c4b4b;
  padding-left: 10px;
  margin-top: 0px;
  line-height: 0px;
  width: 100%;
}

.ctable .mat-form-field-underline {
  display: none !important;
}

.ctable .mat-form-field-subscript-wrapper {
  display: none !important;
}

.ctable .mat-form-field-infix {
  border-top: none !important;
}

.ctable textarea {
  border: 1px solid #ccc;
  background: #fafafb;
  padding: 10px !important;
  font-size: 14px;
}

.ctable .ng-select .ng-select-container {
  border: 1px solid #ccc !important;
  background: #fafafb;
}

.ctable .dx-field {
  min-height: 36px !important;
  background: #fafafb !important;
  padding: 0 2px 0 10px !important;
  border: 1px solid #ccc !important;
}

.ctable .dx-texteditor.dx-editor-standard {
  width: auto !important;
}

.ctable .dx-texteditor-input {
  font-size: 13px;
  border: none;
  font-weight: 200 !important;
  padding-top: 10px !important;
}

.ctable .dx-texteditor.dx-editor-standard:after {
  border-bottom: 0px solid #fff !important;
  font-weight: 200 !important;
}

.dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-standard.dx-show-clear-button .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-standard.dx-show-clear-button .dx-texteditor-input {
  padding-right: 48px !important;
}

.ctable .ng-select-container {
  background: #fafafb;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc !important;
}

.shipped-bg {
  background: #7fb36f !important;
}

.bg-low-brand {
  background-color: #cbf8fc;
}

.bg-low-material {
  background-color: rgb(197, 248, 201);
}

/*

.custom-filter-header .dx-texteditor-input {
  font-weight: 200 !important;
  padding-right: 20px !important;
}
.custom-filter-header .dx-texteditor.dx-editor-standard:after {
  border-bottom: 0px solid #fff !important;
  font-weight: 200 !important;
}

.custom-filter-header .dx-texteditor-input{ 
  font-size: 13px;
  margin-top: 2px;
  padding-right: 20px !important;
}
.custom-filter-header .mat-multiple-select{
  background: #fff;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc !important;
}
*/

.ctable .sorter-icon {
  position: absolute;
  right: 5px;
  top: calc(50% - 8px);
  height: 16px;
  margin-left: 5px;
}

.mat-dialog-actions {
  float: right !important;
}

.req {
  color: #cf0000;
  padding: 0 2px;
}

.cpagination .total-text {
  padding-left: 20px;
  color: #2299e8;
}

.cpagination .pagination {
  display: inline-block;
}

.cpagination .item-per-page-select-text {
  padding-left: 20px;
  color: #2299e8;
}

.cpagination .item-per-page-select {
  width: 50px;
  background: #fafafb;
  height: 22px;
  padding: 2px;
  border: 1px solid #2299e8;
  text-align: center;
}

.bg-low-planned {
  background-color: rgb(197, 248, 201);
}

.nav.material2 .nav-group>.group-title,
.material2 .nav .nav-group>.group-title {
  height: 0px !important;
  padding: 0;
  margin: 0;
}

.nav.material2 .nav-group>.group-title,
.material2 .nav .nav-group>.group-title .hint-text {
  font-size: 0px !important;
}

.custom-filter-header .ng-select-container {
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc !important;
}

.custom-filter-header .ng-select-container .ng-value-container .ng-input {
  top: 8px !important;
  padding-left: 0 !important;
}

.ng-dropdown-panel {
  width: auto !important;
  max-width: 400px !important;
}

.ng-value-container {
  .ng-value {
    // white-space: normal !important;
    min-width: 100px;
    max-width: 100% !important;
  }
}

.ng-value-container {
  white-space: nowrap !important;
  width: 50px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}



// .ng-select.auto-grow {
//   display: flex;

// .ng-select-container {
//   min-width: 100px;
//   max-width: 100% !important;
// }
// }

// .ng-value-container {
//   .ng-value {
//     white-space: normal !important;
//   }
// }

.collapsable {
  line-height: 16px;
  font-size: 26px !important;
}